/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-06 11:25:23
 * @LastEditTime: 2019-09-24 20:07:49
 * @LastEditors: Please set LastEditors
 */
/* eslint-disable */
import sa from 'sa-sdk-javascript'

const sensorsdata = {
 /**
   * 触发搜索页（前端）
   * v1_searchSource	触发来源	数值	
        1-医生站app-首页
        2-医生站app-课程
        3-医生站app-免费课
        4-医生站app-收藏

        100-医学界
        101-医学界智库
        102-医生站
        103-医学界儿科频道
        104-医学界妇产科频道
        105-医学界肿瘤频道
        106-医学界影像诊断与介入频道
        107-医学界风湿免疫频道
        108-医学界护理频道
        109-医学界消化肝病频道
        110-医学界骨科频道
        111-医学界感染频道
        111-医学界内分泌频道
        111-医学界神经病学频道
        111-医学界麻醉频道
        111-医学界临床药学频道
        111-医学界精神病学
        111-医学界外科频道
        111-医学界心血管频道
        111-医学界检验频道
        111-医学界呼吸频道
        111-医学界急诊与重症频道
        111-医学界精品课
        111-医学界健康生活/黑科技
        111-界哥书店
        111-医盟
        50-医生站PC官网
        51-医生站直播间"
      v1_clickMode	触发方式	数值	
        1-app点击按钮
        2-pc点击按钮
        3-微信点击搜索菜单
        4-微信点击内容回复搜索
        5-直播间点击按钮
    *
   */
  v1_ysz_startSearchPage (obj) {
    const data = {
      $lib_detail: obj.$lib_detail,
      v1_searchSource: Number(obj.searchSource), 
      v1_clickMode: Number(obj.clickMode), //
    }
    console.log(data)
    sa.track('v1_ysz_startSearchPage', data)
  },
  /**
   * 曝光搜索结果（前端）
   * v1_searchKeyword	搜索词	字符串	根据用户输入的内容进行取值
    v1_searchContentType	搜索内容类型分类	数值	1-综合，2-文章，3-课程，4-直播间，5-医学会议，6-医学公式，7-药品说明书,8-文献指南
    v1_showSearchResultsNumber	曝光过结果数量	数值	根据有多少搜索结果被用户所看到进行计数展示
    用户在离开当前搜索结果页之后进行上报，包括以下动作：
    1、返回上级页面 2、点击搜索结果项 3、唤出APP 4、刷新 5、切换tab分类标签
    触发事件。
    备注：上下反复滑动，同一内容的多次展示也仍算一次曝光，刷新页面后则另算
    *
   */
  v1_ysz_showSearchResults (obj) {
    const data = {
      $lib_detail: obj.$lib_detail,
      v1_searchKeyword: String(obj.searchKeyword),
      v1_searchContentType: Number(obj.searchContentType),
      v1_showSearchResultsNumber: Number(obj.showSearchResultsNumber),
    }
    console.log(data)
    sa.track('v1_ysz_showSearchResults', data)
  },
  /**
   * 点击搜索结果（前端）
    v1_searchKeyword	搜索词	字符串	根据用户输入取值
    v1_searchContentType	搜索内容类型分类	数值	1-综合，2-文章，3-课程，4-直播间，5-医学会议，6-医学公式，7-药品说明书,8-文献指南
    v1_showSearchResultsNumber	曝光过结果数量	数值	根据有多少搜索结果被用户所看到进行计数展示
    v1_searchPositionNumber	点击结果词列表所在位置	数值	展示用户点击的搜索结果页中第几位的搜索词（序号）
    用户点击了搜索结果列表中的某一结果，触发该事件。
  *
   */
  v1_ysz_clickSearchResults (obj) {
    const data = {
      $lib_detail: obj.$lib_detail,
      v1_searchKeyword: String(obj.searchKeyword),
      v1_searchContentType: Number(obj.searchContentType),
      v1_showSearchResultsNumber: Number(obj.showSearchResultsNumber),
      v1_searchPositionNumber: Number(obj.searchPositionNumber)
    }
    console.log(data)
    sa.track('v1_ysz_clickSearchResults', data)
  },
  /** 点击热门搜索词-v5.2
   * @param {埋点对象} obj
   */
  v1_ysz_clickKeyWord(obj){
    const data = {
      $lib_detail: obj.$lib_detail,
      v1_searchKeyword: String(obj.searchKeyword), // 搜索词 字符串
      v1_searchSource: Number(obj.searchSource) // 来源 数值
    }
    console.log(data)
    sa.track('v1_ysz_clickKeyWord', data)
  },
  /** 点赞（新）-v5.3
   * @param {埋点对象} obj
   */
  v1_ysz_like(obj){
    const data = {
      $lib_detail: obj.$lib_detail,
      v1_likePosition: Number(obj.likePosition), // 点赞位置 0-文章详情下方  1- 底部  
    }
    console.log(data)
    sa.track('v1_ysz_like', data)
  },
  /** 登录/注册统计 - V5.6.0
   * @param {Number} obj.v1_entrance 入口 1-Android，2-Ios，3-话题详情页（H5），4-视频详情页（H5），5-邀请好友分享页（H5），6-助考之星，7-文章详情页（H5），7-文章详情页中间二维码（H5），8-PC端，9-小程序，10-医学工具（H5），11-身份认证（H5），12-微信H5的其他页面
   * @param {Number} obj.v1_platform 平台 1-App，2-微信，3-官网，4-其他H5页面
   * @param {Number} obj.v1_loginAndSignUpMethod 方式 1-一键登录，2-手机验证码，3-手机密码，4-微信
   * @param {String} obj.v1_failReason 失败原因
   * @param {Boolean} obj.v1_isLogin 是否为登录 是为登录，否为注册
  */
  v1_ysz_loginAndSignUp (obj) {
    const data = {
      $lib_detail: obj.$lib_detail,
      v1_entrance: 8,
      v1_platform: 3,
      v1_loginAndSignUpMethod: Number(obj.v1_loginAndSignUpMethod),
      v1_failReason: String(obj.v1_failReason),
      v1_isLogin: Boolean(obj.v1_isLogin)
    }
    if (!obj.v1_failReason) delete data.v1_failReason
    console.log(data)
    sa.track('v1_ysz_loginAndSignUp', data)
  }
}
export default sensorsdata
