/* eslint-disable */
import sa from 'sa-sdk-javascript'

const loginBuriedPoint = {
  /**
  * 点击获取验证码
    v1_checkInSource	来源	数值	1-登录/注册，2-找回密码，3-设置密码，4-绑定手机号，5-修改手机号
    v1_isSuccess	是否成功	布尔
    v1_failReason	失败原因	字符串
    "如果获取失败，记录具体原因
    前端：手机号不能为空，请输入11位手机号，
    后端：手机号格式错误，"
  * 前端点击获取验证码时，有结果时触发
  */
  v1_ysz_getCode (obj) {
    const data = {
      $lib_detail: obj.$lib_detail,
      v1_checkInSource: Number(obj.v1_checkInSource),
      v1_isSuccess: Boolean(obj.v1_isSuccess),
      v1_failReason: obj.v1_failReason
    }
    console.log(data)
    sa.track('v1_ysz_getCode', data)
  },
  /**
   * 注册
    v1_entrance	入口	数值	1-Android，2-Ios，3-话题详情页（H5），4-视频详情页（H5），5-邀请好友分享页（H5），6-助考之星，7-文章详情页（H5），7-文章详情页中间二维码（H5），8-PC端，9-小程序，10-医学工具（H5），11-身份认证（H5），12-微信H5的其他页面
    v1_isSuccess	是否成功	布尔
    v1_failReason	失败原因	字符串
    v1_signUpMethod	注册方式	数值	1-手机验证码，2-微信
  *
   */
  v1_ysz_signUp (obj) {
    const data = {
      $lib_detail: obj.$lib_detail,
      v1_entrance: Number(obj.v1_entrance),
      v1_isSuccess: Boolean(obj.v1_isSuccess),
      v1_failReason: obj.v1_failReason,
      v1_signUpMethod: Number(obj.v1_signUpMethod)
    }
    console.log(data)
    sa.track('v1_ysz_signUp', data)
  },
  /**
   * 登陆
    v1_entrance	入口	数值	1-Android，2-Ios，3-话题详情页（H5），4-视频详情页（H5），5-邀请好友分享页（H5），6-助考之星，7-文章详情页（H5），7-文章详情页中间二维码（H5），8-PC端，9-小程序，10-医学工具（H5），11-身份认证（H5），12-微信H5的其他页面
    v1_isSuccess	是否成功	布尔
    v1_failReason	失败原因	字符串
    v1_loginMethod	登录方式	数值	1-手机验证码，2-微信，3-手机密码
  *
   */
  v1_ysz_login (obj) {
    const data = {
      $lib_detail: obj.$lib_detail,
      v1_entrance: Number(obj.v1_entrance),
      v1_isSuccess: Boolean(obj.v1_isSuccess),
      v1_failReason: obj.v1_failReason,
      v1_loginMethod: Number(obj.v1_loginMethod)
    }
    console.log(data)
    sa.track('v1_ysz_login', data)
  }

}
export default loginBuriedPoint
