import { api } from '@/utils/fetch'

/** 手机号密码登录 */
const signIn = params => api('user/signIn', params)

/** 获取短信验证码 */
const getSMSCode = params => api('user/getSMSCode', params)

/** 验证码注册/登录 */
const registerLogin = params => api('user/registerLogin', params)

/** 设置邀请码 */
const confirmInviteCode = params => api('user/confirmInviteCode', params)

/** 设置密码 */
const setPassword = params => api('user/setPassword', params)

/** 忘记密码 */
const forgetPassword = params => api('user/forgetPassword', params)

/** 检查登录状态 */
const checkSession = params => api('user/checkSession', params)

/** 保存用户基本信息 */
const saveUserInfo = params => api('user/saveUserInfo', params)

/** 获取科室信息 */
const getDepartmentList = params => api('user/getDepartmentList', params)

/** 获取频道列表（用户未登录） */
const getCategoryList = params => api('content/getCategoryList', params)

/** 设置关注频道 */
const setCategoryFocus = params => api('content/setCategoryFocus', params)

/** 获取用户信息 */
const getUserInfo = params => api('user/getUserInfo', params)

/** 获取标签列表 */
const getClassifyList = params => api('content/getClassifyList', params)

/** 设置用户标签 */
const setUserClassify = params => api('content/setUserClassify', params)

/** 用户信息同步 */
const synchroUserInfo = params => api('user/synchroUserInfo', params)

/** 获取职称列表 */
const getTitleList = params => api('user/getTitleList', params)

/** 获取地区列表 */
const getProvinceList = params => api('user/getProvinceList', params)

/** 获取城市列表 */
const getCityList = params => api('user/getCityList', params)

/** 获取城市列表 */
const getDistrictList = params => api('user/getDistrictList', params)

/** 获取城市列表 */
const getHospitalList = params => api('user/getHospitalList', params)

/** 获取学校列表 */
const getSchoolList = params => api('user/getSchoolList', params)

/** 获取专业列表 */
const getMajorList = params => api('user/getMajorList', params)


export {
  signIn,
  getSMSCode,
  registerLogin,
  confirmInviteCode,
  setPassword,
  forgetPassword,
  checkSession,
  saveUserInfo,
  getDepartmentList,
  setCategoryFocus,
  getUserInfo,
  getCategoryList,
  getClassifyList,
  setUserClassify,
  synchroUserInfo,
  getTitleList,
  getProvinceList,
  getCityList,
  getDistrictList,
  getHospitalList,
  getSchoolList,
  getMajorList
}
