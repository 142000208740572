<template>
  <div>
    <Header/>
    <router-view class="view" />
  </div>
</template>

<script>
import Header from './components/Header'
import { getUserInfo } from '@/api/login'
import { mapMutations } from 'vuex'
export default {
  created() {
    this.getUserInfo()
  },
  methods: {
    ...mapMutations(['setUserInfo', 'setBaseInfo']),
    /** 获取用户信息 */
    getUserInfo () {
      let self = this
      getUserInfo()
      .then( res => {
        let self = this
        self.setUserInfo(res)
        self.setBaseInfo(res)
      })
      .catch( res => {
        console.log(res)
      })
    }
  },
  components:{
    Header
  }
}
</script>

<style lang="scss">
@import './index.scss';
</style>