<template>
  <div>
    <div class="medic-header">
      <div class="medic-container flex-between-center" style="height: 80px">
        <div class="logo-wrapper flex-start-center">
          <a href="#/medicine" class="flex-start-center">
            <img width="219" src="@/assets/medicine/page_logo@2x.png" alt="">
          </a>
        </div>
        <div class="link-wrapper flex-center-center">
          <router-link class="link medic-hover-info" :class="{'router-link-active': $route.name == item.name}" :to="{path: item.to, query: item.query}" v-for="(item,index) in linkList" :key="index">{{item.name}}</router-link>
        </div>
        <div class="login-wrapper flex-start-center">
          
          <div class="collection medic-hover-info" @click="openCollection">收藏夹</div>
          <div class="login" @click="login" v-if="!userInfo.nickName">登录</div>
          <el-dropdown
            trigger="click"
            @command="handleCommand"
            class="userinfo-dropdown"
            style="cursor: pointer;"
            v-if="userInfo.nickName"
          >
            <div class="user-info flex-start-center medic-hover-primary">
              <img class="avater" src="@/assets/medicine/denglu-icon@2x.png" alt="">
              {{userInfo.nickName}}
              <!-- <span @click="exit">退出</span> -->
            </div>
            <el-dropdown-menu>
              <el-dropdown-item command="perfectInfo">个人信息</el-dropdown-item>
              <el-dropdown-item command="exit">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          
        </div>
      </div>
    </div>
    <component :is="model" @close="closeComponent"></component>
    <Collection ref="collect" />
  </div>
</template>

<script>
import Login from './Login'
import Collection from '@/views/herbalMedicine/components/Collection'
import UserInfo from '@/views/herbalMedicine/components/UserInfo'
import { clearCookie, clearAllCookie } from '@/utils/common'
import { mapState, mapMutations } from 'vuex'
import eventBus from '@/eventBus'
export default {
  data() {
    return {
      model: null, // component 组件名
      selectIndex: 0, // 0 首页 1 中草药 2 中成药
      // 导航信息
      linkList: [
        {
          id: 0,
          name: '首页',
          to: '/medicine/searchHome',
          query: {}
        },
        {
          id: 1,
          name: '中草药',
          to: '/medicine/herbalMedicine',
          query: {
            medicType: '1',
            searchType: '-1',
            searchText: ''
          }
        },
        // {
        //   id: 2,
        //   name: '中成药',
        //   to: '/medicine/patentMedicine',
        //   query: {
        //     medicType: '2',
        //     searchType: '-1',
        //     searchText: ''
        //   }
        // }
      ],
      collectObj: null
    }
  },
  created() {
    this.$nextTick(res => {
      eventBus.$on('goLogin', (obj) => {
        this.model = Login;
        this.collectObj = obj;
      })
      eventBus.$on('changeCollection', bool => {
        this.$refs.collect.changeDrawer(bool);
      })
    })
  },
  methods: {
    ...mapMutations(['setUserInfo', 'setBaseInfo']),
    // 点击登录按钮
    login() {
      if(this.model) {
        this.model = null
        return
      }
      this.model = Login;
    },
    // 点击收藏夹
    openCollection() {
      // 判断是否是登陆状态
      if(this.userInfo.nickName) {
        this.$refs.collect.changeDrawer();
      }else {
        this.model = Login;
      }
    },
    // component关闭回调
    closeComponent(type) {
      this.model = null;
      // 收藏登录后调用收藏接口
      if(type == 'collection' && this.collectObj) {
        eventBus.$emit('collect', this.collectObj);
      }
      this.collectObj = null;
    },
    /** 退出登录 */
    exit(){
      this.$confirm('退出后将无法进行收藏并查看收藏夹，是否依旧退出？', {
        confirmButtonText: '再看看',
        cancelButtonText: '退出',
        type: 'warning'
      }).then(() => {
      }).catch(() => {
        let self = this;
        clearAllCookie()
        self.setUserInfo({})
        self.setBaseInfo({})
        location.reload();
      });
    },
    handleCommand(key) {
      if (key === 'exit') {
        this.exit();
      } else if (key === 'perfectInfo') {
        this.model = UserInfo;
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  components: {
    Collection
  },
  watch: {
    $route: {
      handler(route){
        let type = 0;
        switch (route.path) {
          case '/medicine/herbalMedicine/searchContent':
            type = 1;
            break;
          case '/medicine/patentMedicine/searchContent':
            type = 2;
            break;
          default:
            type = 0;
            break;
        }
        this.$store.commit('SET_MEDICTYPE', type)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scope>
.medic-header{
  height: 80px;
  line-height: 80px;
  background: #FFFFFF;
  .user-info{
    font-size: 12px;
    font-weight: 400;
    color: #D7000F;
    line-height: 17px;
    .avater{
      width: 22px;
      height: 22px;
      margin-right: 2px;
    }
  }
}
</style>