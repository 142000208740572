<template>
  <div class="registerLogin flex-center-center">
    <div class="register-box">
      <div class="close" @click="closePage">
        <i class="el-icon-circle-close"></i>
      </div>
      <!-- 登陆注册 -->
      <div v-if="reLogConType===0">
        <!-- 登录 -->
        <div class="registration" v-if="contentType===0">
          <div class="registration-top">
            <div :class="['desk-code',{code:codeIconShow,desk:!codeIconShow}]" @click.prevent="isDesk=!isDesk"></div>
            <div :class="['prompt',{codePrompt:codeIconShow,deskPrompt:!codeIconShow}]">{{codeIconShow?'扫码关注公众号':'验证码或短信登录在这里'}}</div>
          </div>
          <div class="registration-content-desk" v-if="isDesk">
            <div class="content-tab">
              <span :class="{selected:!isPassword}" @click="changelogin(1)">验证码登录</span>
              <span :class="{selected:isPassword}" @click="changelogin(0)">账号密码登录</span>
            </div>
            <div class="content-input">
              <p class="errPrompt"><span v-show="errMsg"><img src="../../../../assets/medicine/tips_error_icon.png" alt="">{{errMsg}}</span></p>
              <div :class="['inputBox',{inputVelue:(mobile||isMobileFocus)}]">
                <input type="tel" v-model.trim="mobile" maxlength="11" @input="checkValue('tel')" placeholder="手机号" @focus="isMobileFocus=true;errMsg=''" @blur="isMobileFocus=false">
              </div>
              <div :class="['inputBox',{inputVelue:(smsCode||isCodeFocus)}]" v-if="!isPassword">
                <input class="codeInput" type="text" v-model.trim="smsCode" maxlength="6" @input="checkValue('code')" placeholder="验证码" @focus="isCodeFocus=true;errMsg=''" @blur="isCodeFocus=false">
                <span class="sendCode" @click="getSMSCode('login')" v-if="firClickGetCodeState">获取验证码</span>
                <span class="sendCode" @click="getSMSCode('login')" v-else-if="!firClickGetCodeState && flag">重新发送</span>
                <span class="sendCode" v-else-if="!firClickGetCodeState && !flag">{{count}}s</span>
              </div>
              <div :class="['inputBox',{inputVelue:(password||isPassFocus)}]" v-if="isPassword">
                <input class="passInput" type="password" v-model.trim="password" placeholder="密码" @input="checkValue('password')" @focus="isPassFocus=true;errMsg=''" @blur="isPassFocus=false" maxlength="20">
                <span class="forgetPass" @click="findPass">忘记密码?</span>
              </div>
              <p class="protocol">未注册手机号验证后自动登录，注册即代表您已经同意<a :href="serviceAgreementUrl" style="color:#E93F35" target="view_window">《用户协议》</a>和<a :href="privacyAgreementUrl" style="color:#E93F35" target="view_window">《隐私条款》</a></p>
            </div>
            <div :class="['content-btn',{btnClick:isClick}]" v-if="!isPassword" @click="register">登录/注册</div>
            <div :class="['content-btn',{btnClick:isClick}]" v-if="isPassword" @click="register">登录</div>
          </div>
          <div class="registration-content-code" v-if="!isDesk">
            <img src="../../../../assets/medicine/qrcode_wechat.png" alt="">
            <p class="code-prompt">关注微信公众号</p>
            <p class="code-bref">每周听个好讲座</p>
          </div>
        </div>
        <!-- 设置邀请码和密码 -->
        <div class="registration setting" v-if="contentType===1">
          <div :class="['inputBox',{inputVelue:(invCode||isICodeFocus)}]">
            <input type="text" style="display:none">
            <input type="text" autocomplete="off" style="display:none">
            <input type="text" v-model.trim="invCode" placeholder="邀请码" @focus="isICodeFocus=true" @blur="isICodeFocus=false" autocomplete="new-password">
          </div>
          <div :class="['inputBox',{inputVelue:(setPass||isSPassFocus)}]">
            <input type="text" autocomplete="off" style="display:none">
            <input type="password" style="display:none">
            <input type="password" v-model.trim="setPass" placeholder="密码" @input="checkValue('setPass')" @focus="isSPassFocus=true" @blur="isSPassFocus=false" maxlength="20" autocomplete="new-password">
          </div>
          <p class="setting-prompt">请至少输入六位以上密码</p>
          <p class="protocol">未注册手机号验证后自动登录，注册即代表您已经同意<a :href="serviceAgreementUrl" style="color:#E93F35" target="view_window">《用户协议》</a>和<a :href="privacyAgreementUrl" style="color:#E93F35" target="view_window">《隐私条款》</a></p>
          <div :class="['content-btn',{btnClick:isNext}]" @click="setInvAndPass()">下一步</div>
          <p class="leapfrog" @click="reLogConType=1">跳过</p>
        </div>
        <!-- 重置密码 -->
        <div class="registration resetPassword" v-if="contentType===2">
          <div class="content-tab">
            <p>重置密码</p>
          </div>
          <div :class="['inputBox',{inputVelue:(mobile||isMobileFocus)}]">
            <input type="tel" v-model.trim="mobile" maxlength="11" @input="checkValue('tel')" placeholder="手机号" @focus="isMobileFocus=true" @blur="isMobileFocus=false" autocomplete="off">
          </div>
          <div :class="['inputBox',{inputVelue:(smsCode||isCodeFocus)}]">
            <input class="codeInput" type="text" v-model.trim="smsCode" maxlength="6" @input="checkValue('code')" placeholder="验证码" @focus="isCodeFocus=true" @blur="isCodeFocus=false;errMsg=''" autocomplete="off">
            <span class="sendCode" @click="getSMSCode('resetPassword')" v-if="firClickGetCodeState">获取验证码</span>
            <span class="sendCode" @click="getSMSCode('resetPassword')" v-else-if="!firClickGetCodeState && flag">重新发送</span>
            <span class="sendCode" v-else-if="!firClickGetCodeState && !flag">{{count}}s</span>
          </div>
          <div :class="['inputBox',{inputVelue:(newPassword||isNPassFocus)}]">
            <input type="password" v-model.trim="newPassword" placeholder="密码" @input="checkValue('newPassword')" @focus="isNPassFocus=true;errMsg=''" @blur="isNPassFocus=false" autocomplete="off" maxlength="20">
          </div>
          <p class="setting-prompt">请至少输入六位以上密码</p>
          <div :class="['content-btn',{btnClick:isSure}]" @click="forgetPassword()">重置密码</div>
        </div>
        <!-- 不符合活动需求 -->
        <div class="registration noPermission" v-if="contentType===3">
          <img src="../../../../assets/medicine/qrcode_wakeApp.png" alt="">
          <h4>对不起，您不符合此次活动需求</h4>
          <p>扫描二维码，登录医学界医生站App</p>
          <p>获取更多活动信息</p>
        </div>
      </div>
      <!-- 选择身份 -->
      <div v-else-if="reLogConType===1" class="select-box department userType">
        <div class="tab-top">
          <span>选择身份</span>
        </div>
        <p class="content-desc">我们将根据您的选择,向您推荐您更感兴趣的内容</p>
        <div class="floor first-floor">
          <ul class="floor-content">
            <li v-for="item in userTypeList" :key="item.typeId" @click="saveUserInfo('type',item.typeId)">
              <img :src="item.url" alt="" v-if="item.typeId!=userType">
              <img :src="item.selectedUrl" alt="" v-if="item.typeId==userType">
              <span :class="{selectedFir:(item.typeId==userType)}">{{item.name}}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 选择科室 -->
      <div v-else-if="reLogConType===2" class="select-box category department">
        <div class="tab-top">
          <span>选择科室</span>
        </div>
          <p class="content-desc" style="margin-bottom:0px !important">我们将根据您的选择,向您推荐您更感兴趣的内容</p>
        <div class="floor first-floor">
          <p class="floor-title">一级分类</p>
          <ul class="floor-content">
            <li v-for="(item,index) in firDepList" :key="item.dptid" @click="selectSecDepList(index+1)">
              <span :class="{selectedSec:(item.dptid==userInfo.firDepid)}">{{item.name}}</span>
            </li>
          </ul>
        </div>
        <div class="floor">
          <p class="floor-title">二级分类</p>
          <ul class="floor-content">
            <li v-for="item in secDepList" :key="item.dptid">
              <span :class="{newWidth:(item.name.length>5),selectedSec:(item.name==userInfo.department)}" @click="saveUserInfo('department',item.name)">{{item.name}}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 关注频道 -->
      <div v-else-if="reLogConType===3" class="select-box category">
        <div class="tab-top">
          <span>管理关注</span>
        </div>
        <p class="content-desc" style="margin-bottom:0px !important">我们将根据您的选择,向您推荐您更感兴趣的内容</p>
        <div class="floor first-floor">
          <p class="floor-title">已关注<span>&nbsp;&nbsp;（拖动排序）&nbsp;&nbsp;</span></p>
          <ul class="floor-content">
            <draggable v-model="fouceCategoryList">
              <transition-group>
                <li v-for="item in fouceCategoryList" :key="item.catid">
                  <span>{{item.catName}}</span>
                  <img src="../../../../assets/medicine/delete_icon.png" alt="" @click="cancelFouceCategory(item)">
                </li>
              </transition-group>
            </draggable>
          </ul>
        </div>
        <div class="floor">
          <p class="floor-title">未关注<span>&nbsp;&nbsp;（点击关注）&nbsp;&nbsp;</span></p>
          <ul class="floor-content">
            <li v-for="(item,index) in categoryList" :key="index" @click="fouceCategory(item)">
              <span>{{item.catName}}</span>
            </li>
          </ul>
        </div>
        <div class="footer-btn">
          <span @click="setCategoryFocus">确认</span>
          <span class="cancel" @click="cancelCategoryFocus">取消</span>
        </div>
      </div>

      <!-- 选择标签 -->
      <div v-else-if="reLogConType===4" class="select-box category">
        <div class="tab-top">
          <span>选择内容偏好</span>
        </div>
        <p class="content-desc">我们将根据您的选择，向您推荐您感兴趣的内容</p>
        <div class="label-content">
          <span class="label-item"  :class="{'label-item-active':item.focused,'m-right0':index%4==3}" v-for="(item,index) in labelArr" :key="item.classifyId" @click="labelClick(item)">{{item.name}}</span>
        </div>
        <div class="footer-label" @click="setClassify">
          开始体验
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { signIn, getSMSCode, registerLogin, confirmInviteCode, setPassword, forgetPassword,checkSession, saveUserInfo, getDepartmentList, getCategoryList, setCategoryFocus, getUserInfo, getClassifyList, setUserClassify } from '@/api/login'
import { setCookie, clearCookie, getCookie, clearAllCookie } from '@/utils/common'
import { mapMutations } from 'vuex'
import storage from "storejs"
import draggable from 'vuedraggable'
import sa from 'sa-sdk-javascript'
import loginBuriedPoint from "@/utils/loginBuriedPoint";
import sensorsdata from '@/utils/sensorsdata.js'

export default {
  components: {
    draggable
  },
  data (){
    return {
      mobile:null,
      password:null,
      smsCode:null,
      count: 60,
      errMsg: "",//错误提示
      firClickGetCodeState:true,//是否首次点击获取验证码状态
      flag: true, // 是否可以点击发送验证码
      isPassword: false, // 是否是密码登录
      isDesk:true,//登录公众号切换[true-登录;false-公众号]
      isMobileFocus: false, // 手机输入框是否聚焦
      isCodeFocus: false, // 验证码输入框是否聚焦
      isPassFocus: false, // 密码输入框是否聚焦
      isMsgSmsLogin:false,//密码输入错误，通过文案提示进入验证码登录
      codeIconShow:true,//二维码图标显示[true-二维码图标；false-电脑图标]
      contentType:0,//盒子内容[0-登录;1-设置邀请码和密码;2-重置密码;3-没有领奖资格弹窗]
      firstRegister:false,//是否首次注册登录
      invCode:'',//邀请码
      setPass:null,//设置密码
      isICodeFocus: false, // 设置密码输入框是否聚焦
      isSPassFocus: false, // 设置密码输入框是否聚焦
      newPassword:null,//重置密码
      isNPassFocus: false, // 重置密码输入框是否聚焦
      reLogConType:0,//该页面展示内容[0-登陆注册;1-选择身份;2-选择科室;3-关注频道;4-选择标签]
      isNewUser:false,//是否是新用户
      userType:null,//用户身份【0-缺省值,1-医生,2-行政,3-药师,4-护士,5-医学生,6-其他,7-医技】 
      userTypeList:[
        {typeId:1,name:'医生',url:'https://studioyszimg.yxj.org.cn/2019061220463800131.png',selectedUrl:'https://studioyszimg.yxj.org.cn/2019061220465700132.png'},
        {typeId:4,name:'护士',url:'https://studioyszimg.yxj.org.cn/2019061220473300133.png',selectedUrl:'https://studioyszimg.yxj.org.cn/2019061220474800134.png'},
        {typeId:7,name:'医技',url:'https://studioyszimg.yxj.org.cn/2019061220484800135.png',selectedUrl:'https://studioyszimg.yxj.org.cn/2019061310230100000.png'},
        {typeId:2,name:'行政',url:'https://studioyszimg.yxj.org.cn/2019061220492300136.png',selectedUrl:'https://studioyszimg.yxj.org.cn/2019061220494500137.png'},
        {typeId:3,name:'药师',url:'https://studioyszimg.yxj.org.cn/2019061220514900142.png',selectedUrl:'https://studioyszimg.yxj.org.cn/2019061220520400143.png'},
        {typeId:5,name:'医学生',url:'https://studioyszimg.yxj.org.cn/2019061220501700138.png',selectedUrl:'https://studioyszimg.yxj.org.cn/2019061220503200139.png'},
        {typeId:6,name:'其他',url:'https://studioyszimg.yxj.org.cn/2019061220511800141.png',selectedUrl:'https://studioyszimg.yxj.org.cn/2019061220510300140.png'}
      ],//用户身份列表
      firDepList:[],//一级科室列表
      secDepList:[],//二级科室列表
      userInfo:{},//用户信息
      categoryList:[],//未关注频道列表
      fouceCategoryList:[],//关注的频道列表
      click:true,//放多次点击
      showTimer:null,//弹窗显示定时器
      isRewardCoupon:false,//是否显示领奖页【true-是，false-否】
      toback:false,//取消选择频道是否返回到身份【true-是，false-否】
      labelArr:[],
      serviceAgreementUrl:'http://www.yishengzhan.cn/serviceAgreement.html?t=' + parseInt(+new Date()) ,
      privacyAgreementUrl:'http://www.yishengzhan.cn/privacyAgreement.html?t=' + parseInt(+new Date())
    }
  },
  computed: {
    isClick: function() {
      let mobileReg = /^\d{11}$/;
      let codeReg = /^\d{4,}$/;
      if (this.isPassword) {
        // 密码登录
        if (
          this.mobile != "" &&
          mobileReg.test(this.mobile) &&
          this.password != "" &&
          this.password.length >= 6
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        // 验证码登录
        if (
          this.mobile != "" &&
          mobileReg.test(this.mobile) &&
          this.smsCode != "" &&
          codeReg.test(this.smsCode)
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    isNext: function(){
      //设置密码
      if (
        this.setPass &&
        this.setPass.length > 5
      ) {
        return true;
      } else {
        return false;
      }
    },
    isSure: function(){
      let mobileReg = /^\d{11}$/;
      let codeReg = /^\d{4,}$/;
      //重置密码
      if (
        this.mobile != "" &&
        mobileReg.test(this.mobile) &&
        this.smsCode != "" &&
        codeReg.test(this.smsCode) &&
        this.newPassword &&
        this.newPassword.length > 5
      ) {
        return true;
      } else {
        return false;
      }
    },
    isSendCode: function() {
      let mobileReg = /^\d{11}$/;
      if (this.mobile != "" && mobileReg.test(this.mobile)) {
        return true;
      } else {
        return false;
      }
    },
    isSetLabel: function(params) {
      let self = this
      let arr = []
      for (let i = 0; i < self.labelArr.length; i++) {
        if (self.labelArr[i].focused) {
          arr.push(self.labelArr[i].classifyId)
        }        
      }
      if (arr.length<1) {
        return false
      }else {
        return true
      }
    }
  },
  mounted(){
    let self = this;
    self.hideLoad();
    checkSession({}).then(res=>{
      console.log(getCookie('focusCats'))
      self.checkUserInfoPerfect('first')
    }).catch(res=>{
      if(res.code == 999){
        self.contentType = 0;
        clearAllCookie()
      }else{
        console.log(res)
      }
    })
    self.getClassifyList()
    // this.getCategoryList()
  },
  methods:{
    ...mapMutations(["showLoad","hideLoad",'setUserInfo', 'setBaseInfo']),
    checkValue(type){
      //手机号校验
      if(type=="tel"){
        this.mobile = this.mobile.replace(/[^\d]/g,'');
        if (this.mobile.length > 11) {
          this.mobile = this.mobile.slice(0, 11);
        }
      }else if(type=="code"){
        //验证码校验
        this.smsCode = this.smsCode.replace(/[^\d]/g,'')
        if (this.smsCode.length > 6) {
          this.smsCode = this.smsCode.slice(0, 6);
        }
      }else if(type=="password"){
        //密码校验
        this.password = this.password.replace(/\s+/g,'')
      }else if(type=="setPass"){
        //密码校验
        this.setPass = this.setPass.replace(/\s+/g,'')
      }else if(type=="newPassword"){
        //密码校验
        this.newPassword = this.newPassword.replace(/\s+/g,'')
      }
    },
    /**
     * 手机号密码登录
     */
    passwordLogin(){
      //click 防多次点击[true-可点击;false-不可点击]
      if(this.click==false){
        return false
      }
      this.click = false;
      let data ={
        mobile:this.mobile,
        password:this.password,
        source: 2 //来源【1-CX，2-PC,3-Android,4-iOS,5-Wechat,6-文章分享登录 7-优惠券领取,8-助考之星,9-身份认证,10-二维码进入助考之星注册,11-文章分享进入助考之星注册】
      }
      signIn(data).then(res=>{
        
        sa.login(res.uid)

        this.click = true;
        this.showReLogContent(res)
        /** 4.8.0 新增埋点 start */ 
        let brData = {
          $lib_detail: "v1_ysz_login##signIn##registerLogin.vue##367",
          v1_entrance: 8,
          v1_isSuccess: true,
          v1_failReason: '',
          v1_loginMethod: 3
        }
        loginBuriedPoint.v1_ysz_login(brData)
        /** 4.8.0 新增埋点 end */ 
        this.scDataloginAndSignUp(3,true)
      }).catch(err=>{
        this.click = true;
        this.errMsg = err.msg;
        console.log(err)
        /** 4.8.0 新增埋点 start */ 
        let brData = {
          $lib_detail: "v1_ysz_login##signIn##registerLogin.vue##381",
          v1_entrance: 8,
          v1_isSuccess: false,
          v1_failReason: err.msg,
          v1_loginMethod: 3
        }
        loginBuriedPoint.v1_ysz_login(brData)
        /** 4.8.0 新增埋点 end */ 
        this.scDataloginAndSignUp(3,true,err.msg)
      })
    },
    /**
     * 验证码注册/登录
     */
    smsCodeLogin(){
      //click 防多次点击[true-可点击;false-不可点击]
      if(this.click==false){
        return false
      }
      this.click = false;
      let data ={
        mobile:this.mobile,
        smsCode:this.smsCode,
        source: 2 //来源【1-CX，2-PC,3-Android,4-iOS,5-Wechat,6-文章分享登录 7-优惠券领取,8-助考之星,9-身份认证,10-二维码进入助考之星注册,11-文章分享进入助考之星注册】
      }
      registerLogin(data).then(res=>{
        console.log(res)
        sa.login(res.uid)
        this.click = true;
        /** 4.8.0 新增埋点 start */ 
        let brData = {
          $lib_detail: "v1_ysz_login##registerLogin##registerLogin.vue##410",
          v1_entrance: 8, // 8-PC端
          v1_isSuccess: true,
          v1_failReason: ''
        }
        if (!res.firstRegister) { // 登陆
          brData.v1_loginMethod = 1
          loginBuriedPoint.v1_ysz_login(brData)
        } else {
          brData.$lib_detail = "v1_ysz_signUp##registerLogin##registerLogin.vue##419"
          brData.v1_signUpMethod = 1
          loginBuriedPoint.v1_ysz_signUp(brData)
        }
        /** 4.8.0 新增埋点 end */ 
        this.scDataloginAndSignUp(2,!res.firstRegister)
        this.showReLogContent(res)
      }).catch(err=>{
        this.click = true;
        console.log(err)
        this.errMsg = err.msg
        /** 4.8.0 新增埋点 start */ 
        let brData = {
          $lib_detail: "v1_ysz_login##registerLogin##registerLogin.vue##431",
          v1_entrance: 8,
          v1_isSuccess: false,
          v1_failReason: err.msg
        }
        // 607,用户已注册，610,用户未注册
        if (err.code == 607) {
          brData.v1_loginMethod = 1
          loginBuriedPoint.v1_ysz_login(brData)
          this.scDataloginAndSignUp(2,true,err.msg)
        } else if (err.code == 610)  {
          brData.v1_signUpMethod = 1
          brData.$lib_detail = "v1_ysz_signUp##registerLogin##registerLogin.vue##442"
          loginBuriedPoint.v1_ysz_signUp(brData)
          this.scDataloginAndSignUp(2,false,err.msg)
        }else { // 无法区分的  默认上报登录。
          brData.v1_loginMethod = 1
          loginBuriedPoint.v1_ysz_login(brData)
          this.scDataloginAndSignUp(2,true,err.msg)
        }
        /** 4.8.0 新增埋点 end */ 
      })
    },
    /**
     * 获取短信验证码
     */
    getSMSCode(type){
      //click 防多次点击[true-可点击;false-不可点击]
      if(this.click==false){
        return false
      }
      this.click = false;
      this.errMsg = "";
      if(!this.isSendCode){
        this.errMsg = "请填写正确的手机号"
        this.click = true;
        /** 4.8.0 新增埋点 start */ 
        let brData = {
          $lib_detail: "v1_ysz_getCode##getSMSCode##registerLogin.vue##466",
          v1_checkInSource: type==='login'?1:2,//1-登录/注册，2-找回密码，3-设置密码，4-绑定手机号，5-修改手机号
          v1_isSuccess: false,
          v1_failReason: this.mobile === ""?'手机号不能为空':"请输入11位手机号"
        }
        loginBuriedPoint.v1_ysz_getCode(brData)
        /** 4.8.0 新增埋点 end */ 
        return false
      }
      let data ={
        mobile:this.mobile,
        scene:0 //获取短信码的场景【0-登录/注册，1-忘记密码，2-修改手机号，3-微信绑定手机号码】
      }
      getSMSCode(data).then(res=>{
        this.click = true;
        this.firClickGetCodeState = false;
        this.flag = false;
        /** 4.8.0 新增埋点 start */ 
        let brData = {
          $lib_detail: "v1_ysz_getCode##getSMSCode##registerLogin.vue##485",
          v1_checkInSource: type==='login'?1:2,//1-登录/注册，2-找回密码，3-设置密码，4-绑定手机号，5-修改手机号
          v1_isSuccess: true,
          v1_failReason: ''
        }
        loginBuriedPoint.v1_ysz_getCode(brData)
        /** 4.8.0 新增埋点 end */
        this.countdownFun();
      }).catch(err=>{
        this.click = true;
        console.log(err);
        this.errMsg = err.msg;
        this.showMessage(err.msg, "error");
        /** 4.8.0 新增埋点 start */ 
        let brData = {
          $lib_detail: "v1_ysz_getCode##getSMSCode##registerLogin.vue##500",
          v1_checkInSource: type==='login'?1:2,//1-登录/注册，2-找回密码，3-设置密码，4-绑定手机号，5-修改手机号
          v1_isSuccess: false,
          v1_failReason: err.msg
        }
        loginBuriedPoint.v1_ysz_getCode(brData)
        /** 4.8.0 新增埋点 end */
      })
    },
    /**短信倒计时 */
    countdownFun() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            this.count = 60;
            clearInterval(this.timer);
            this.timer = null;
            this.flag = true;
          }
        }, 1000);
      }
    },
    /**切换登录方式
     * @param type [1-验证码登录;2-手机登录]
     */
    changelogin(type) {
      this.smsCode = "";
      this.password = "";
      this.errMsg = "";
      this.isPassword = !this.isPassword;
      if(type===1){
        this.isMsgSmsLogin=true
      }else{
        this.isMsgSmsLogin=false
      }
    },
    /**注册登录*/
    register() {
      if(!this.isClick){
        return false
      }
      let self = this;
      this.errMsg = "";
      if (self.isPassword) {
        // 密码登录
        this.passwordLogin();
      } else {
        // 验证码登录
        this.smsCodeLogin();
      }
    },
    /**页面展示内容 */
    showReLogContent(res){
      console.log(res)
      // sensors.login(res.uid);
      let self = this;
      //设置cookie
      setCookie('uid',res.uid);
      setCookie('session',res.session);
      setCookie('nickName',res.nickName);
      setCookie('headUrl',res.headUrl);
      setCookie('type',res.type);
      setCookie('department',res.department);
      setCookie('phone',res.phone);
      setCookie('isNewUser',res.isNewUser);
      if(res.focusCats == "[]"){
        setCookie('focusCats',"");
      }else{
        let len = res.focusCats;
        var focusCatArray = res.focusCats.substring(1,len-1).split(",")
      }
      setCookie('focusCats',focusCatArray);
      setCookie('firstRegister',res.firstRegister);
      self.userInfo.nickName = res.nickName;
      self.userInfo.headUrl = res.headUrl;
      self.userInfo.type = res.type;
      self.userType = res.type;
      self.userInfo.department = res.department;
      self.setUserInfo(res);
      self.setBaseInfo(res)
      self.firstRegister = res.firstRegister;
      self.isRewardCoupon = res.isRewardCoupon;
      //是否首次注册用户，是进入选择身份，否则跳回原路径
      if(res.firstRegister){
        // self.contentType = 1;
        self.reLogConType = 1;
      }else{
        // 判断是否从其他路径进入登陆页，有则返回原页面，没则跳到首页
        let from_url = storage("from_pc");
        if (from_url) {
          //是否是从本页面操作，若是则需要检验用户信息
          if(from_url == process.env.VUE_APP_PAGE+'index.html#/registerLogin'){
            if(res.isNewUser){
              self.checkUserInfoPerfect('second')
            }else{
              storage.remove("from_pc");
              // self.$router.push({path:'/medicine'})
              self.closePage('collection');
            }
          }else{
            if(res.isNewUser){
              self.checkUserInfoPerfect('hasUrl')
            }else{
              storage.remove("from_pc");
              window.location.href = from_url;
              console.log("from_url" + from_url);
            }
          }
        } else {
          self.checkUserInfoPerfect('second')
        }
      }
    },
    /** 检查用户信息是否完善 */
    checkUserInfoPerfect(types){
      let self = this
      let from_url = storage("from_pc");
      getUserInfo()
      .then( res => {
        console.log(res, '1111111')
        self.setUserInfo(res)
        self.setBaseInfo(res)
        let type = res.type;
        if(!res.type){
          this.reLogConType = 1
        }else if((type==1||type==2||type==3||type==4||type==7)&&!res.department){
          //当选择医生、护士、医技、行政、药师身份时，进入下一步选择科室
          this.getDepartmentList(Number(res.type))
        }
        // else if(res.isRewardCoupon){
        //   storage.remove("from_pc");
        //   this.$router.push({path:'/receivePrize'})
        // }
        else if(types=='hasUrl'){
          storage.remove("from_pc");
          window.location.href = from_url;
        }else if(types=='second'){
          storage.remove("from_pc");
          // this.$router.push({path:'/medicine'})
          this.closePage('collection');
        }else{
          this.reLogConType = 0
        }
        console.log(this.reLogConType)
      })
      .catch( res => {
        // self.showMessage(res.msg, 'error')
        console.log(res)
      })
      
    },
    /**获取科室信息 */
    getDepartmentList(userType){
      getDepartmentList({}).then(res=>{
        let self = this;
        self.reLogConType = 2
        console.log(res)
        switch(userType){
          case 1 : 
            self.firDepList = res['doctor']
            self.secDepList = res['doctor'][0].childs
            self.userInfo.firDepid = res['doctor'][0].dptid
            break;
          case 2 :
            self.firDepList =  res['hospitalManager']
            self.secDepList = res['hospitalManager'][0].childs
            self.userInfo.firDepid = res['hospitalManager'][0].dptid
            break;
          case 3 :
            self.firDepList = res['druggist']
            self.secDepList = res['druggist'][0].childs
            self.userInfo.firDepid = res['druggist'][0].dptid
            break;
          case 4 :
            self.firDepList = res['nurse']
            self.secDepList = res['nurse'][0].childs
            self.userInfo.firDepid = res['nurse'][0].dptid
            break;
          case 7 :
            self.firDepList = res['medicalSkills']
            self.secDepList = res['medicalSkills'][0].childs
            self.userInfo.firDepid = res['medicalSkills'][0].dptid
            break;
        }
      }).catch(res=>{
        console.log(res)
      })
    },
    /** 获取二级科室 */
    selectSecDepList(firIndex){
      this.userInfo.firDepid = this.firDepList[firIndex-1].dptid
      this.secDepList = this.firDepList[firIndex-1].childs;
    },
    /**设置邀请码 */
    confirmInviteCode(){
      if(!this.invCode){
        return false
      }
      let data={
        invCode:this.invCode,
        isValid:true
      }
      confirmInviteCode(data).then(res=>{
        console.log(res)
      }).catch(res=>{
        console.log(res)
        this.showMessage(res.msg, "error");
      })
    },
    /**设置密码 */
    setPassword(){
      if(!this.setPass){
        return false
      }
      //click 防多次点击[true-可点击;false-不可点击]
      if(this.click==false){
        return false
      }
      this.click = false;
      let data={
        password:this.setPass
      }
      setPassword(data).then(res=>{
        this.click = true;
        this.reLogConType = 1;
      }).catch(err=>{
        this.click = true;
        console.log(err)
        this.showMessage(err.msg, "error");
      })
    },
    /**设置邀请码和密码 */
    setInvAndPass(){
      if(!this.setPass || (this.setPass&&this.setPass.length<6) ){
        this.showMessage('请设置六位以上密码', "info");
        return false
      }
      this.setPassword();
      this.confirmInviteCode()
    },
    /**找回密码 */
    findPass(){
      this.mobile = null
      this.smsCode = null
      this.firClickGetCodeState = true
      this.flag = true
      this.contentType = 2
    },
    /**重置密码 */
    forgetPassword(){
      if(!this.isSure || !this.newPassword || (this.newPassword&&this.newPassword.length<6)){
        return false
      }
      //click 防多次点击[true-可点击;false-不可点击]
      if(this.click==false){
        return false
      }
      this.click = false;
      let data={
        mobile:this.mobile,
        smsCode:this.smsCode,
        newPassword:this.newPassword
      }
      forgetPassword(data).then(res=>{
        this.click = true;
        console.log(res)
        this.showMessage('密码修改成功', "success");
        this.smsCode = null;
        this.firClickGetCodeState = true;
        this.flag = true;
        this.contentType = 0;
        this.isPassword = true;
      }).catch(res=>{
        this.click = true;
        console.log(res)
        this.showMessage(res.msg, "error");
      })
    },
    /**设置用户信息 */
    saveUserInfo(property,value){
      saveUserInfo({property:property,value:value}).then(res=>{
        console.log(res)
        if(property=="department"){
          /* this.reLogConType = 3;
          this.userInfo.department = value;
          this.toback = true
          setCookie('department',value); */
          // 选择标签
          this.getClassifyList()
          this.reLogConType = 4
        }else if(property=="type"){
          this.userType = value
          this.toback = true
          if(value==1||value==2||value==3||value==4||value==7){
            this.reLogConType = 2;
            this.userInfo.type = value;
            //获取一级科室
            this.getDepartmentList(Number(value))
          }else{
            // 选择标签
            this.getClassifyList()
            this.reLogConType = 4
          }
          setCookie('type',value);
        }
      }).catch(err=>{
        console.log(err)
        this.showMessage(err.msg, "error");
      })
    },
    /**获取频道列表（用户未登录） */
    getCategoryList(){
      getCategoryList({}).then(res=>{
        console.log(res)
        this.fouceCategoryList = res.list
      }).catch(err=>{
        console.log(err)
      })
    },
    /**点击关注频道 */
    fouceCategory(obj){
      let catIndex;
      //关注频道列表添加该频道
      this.fouceCategoryList.push(obj);
      //未关注频道列表添删除该频道
      this.categoryList.map((item,index)=>{
        if(item.catid == obj.catid){
          catIndex = index
        }
      })
      this.categoryList.splice(catIndex,1)
    },
    /**点击取消关注频道 */
    cancelFouceCategory(obj){
      let catIndex;
      //未关注频道列表添添加该频道
      this.categoryList.unshift(obj);
      //关注频道列表添删除该频道
      this.fouceCategoryList.map((item,index)=>{
        if(item.catid == obj.catid){
          catIndex = index
        }
      })
      this.fouceCategoryList.splice(catIndex,1)
    },
    /**设置关注频道 */
    setCategoryFocus(){
      let catIds = [];
      this.fouceCategoryList.map((item)=>{
        catIds.push(item.catid)
      })
      console.log(catIds)
      if(catIds.length===0){
        this.showMessage("请至少订阅一个频道","info")
        return false
      }
      //click 防多次点击[true-可点击;false-不可点击]
      if(this.click==false){
        return false
      }
      this.click = false;
      let data = {
        catIds:catIds
      }
      console.log(data,'data')
      setCategoryFocus(data).then(res=>{
        this.click = true;
        // 选择标签
        this.getClassifyList()
        this.reLogConType = 4
        setCookie('focusCats',catIds.join());
      }).catch(err=>{
        this.click = true;
        console.log(err)
        this.showMessage(err.msg, "error");
      })
    },
    /**取消设置关注频道 */
    cancelCategoryFocus(){
      //当用户为注册流程进入此流程时,取消回到上一步选择科室
      //当用户为登录用户在首页科室栏选择进入此页面时，取消则不保存结果直接回到首页
      if(this.toback){
        if(this.userType==1||this.userType==2||this.userType==3||this.userType==4||this.userType==7){
          this.reLogConType = 2;
          //获取一级科室
          // this.getDepartmentList(Number(this.userType))
        }else{
          this.reLogConType = 1;
        }
      }else{
        // this.$router.push({path:'/medicine'})
        this.closePage('collection');
      }
    },
    /** 本页退出登录 */
    exit(){
      this.mobile = null;
      this.password = null;
      this.smsCode = null;
      this.invCode='';//邀请码
      this.setPass=null;//设置密码
      this.newPassword = null;
      this.errMsg = "";
      this.isPassword=false; // 是否是密码登录
      this.reLogConType = 0;
      this.contentType =0;
      this.userType = 0;
      this.firDepList = [];//一级科室列表
      this.secDepList = [];//二级科室列表
      this.userInfo = {}
      this.toback = false
      clearTimeout(this.showTimer);
      this.showTimer = null;
    },
    /** 本页注册登录登录 */
    toLogin(){
      this.mobile = null;
      this.password = null;
      this.smsCode = null;
      this.invCode='';//邀请码
      this.setPass=null;//设置密码
      this.newPassword = null;
      this.errMsg = "";
      this.isPassword=false; // 是否是密码登录
      this.reLogConType = 0;
      this.contentType =0;
      this.userType = 0;
      this.firDepList = [];//一级科室列表
      this.secDepList = [];//二级科室列表
      this.userInfo = {}
      this.toback = false
      clearTimeout(this.showTimer);
      this.showTimer = null;
    },
    /** 获取标签 */
    getClassifyList(){
      let self = this
      getClassifyList()
      .then(res=>{
        self.labelArr = res.list
        console.log(res)
      })
      .catch(res=>{
        console.log(res)
      })
    },
    labelClick(item){
      item.focused = item.focused? 0:1
    },
    setClassify(){
      let self = this
      let data = {}
      let arr=[]
      for (let i = 0; i < self.labelArr.length; i++) {
        if (self.labelArr[i].focused) {
          arr.push(self.labelArr[i].classifyId)
        }        
      }
      if (arr.length<1) {
        this.showMessage('请选择内容偏好', "error");
        return false
      }
      setUserClassify({classifyIds:arr})
      .then(res=>{
        console.log(res)
        //当用户为注册流程进入此流程时确认进入下一步至领奖页面
        //当用户为登录用户在首页科室栏选择进入此页面时，确认进入下一步回到首页并保存科室和科室顺序，
        // if(self.isRewardCoupon){
        //   storage.remove("from_pc");
        //   self.$router.push({path:'/receivePrize'})
        // }else{
        storage.remove("from_pc");
        this.closePage('collection');
        // }
      })
      .catch(res=>{
        console.log(res)
      })
      // console.log(arr)

    },
    /** 神策数据埋点-登录/注册统计 v5.6.0
     * @param {Number} loginAndSignUpMethod 方式 1-一键登录，2-手机验证码，3-手机密码，4-微信
     * @param {Boolean} isLogin 是否为登录 是为登录，否为注册
     * @param {String} failReason 失败原因
     */
    scDataloginAndSignUp(loginAndSignUpMethod,isLogin,failReason){
      let data = {
        $lib_detail: "v1_ysz_loginAndSignUp##scDataloginAndSignUp##ArticleDetail.vue##1560",
        v1_loginAndSignUpMethod: loginAndSignUpMethod,
        v1_failReason: failReason?failReason:'',
        v1_isLogin: isLogin
      }
      sensorsdata.v1_ysz_loginAndSignUp(data)
    },
    closePage(type) {
      this.exit();
      this.$emit("close", type)
    }
  },
  beforeDestroy(){
    clearTimeout(this.showTimer);
    this.showTimer = null;
    storage.remove("from_pc");
  }
}
</script>
<style lang="scss" scoped>
.registerLogin{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.6);
  z-index: 100;
  .register-box{
    position: relative;
    .close{
      position: absolute;
      cursor: pointer;
      right: -30px;
      top: -40px;
      &>i{
        color: #ffffff;
        font-size: 30px;
      }
    }
  }
}
.registration{
  margin: 0 auto;
  width:400px;
  height:538px;
  background:#fff;
  box-shadow:0px 0px 20px 0px rgba(0,0,0,0.14);
  border-radius:6px;
}
.registration-top{
  height: 84px;
  .desk-code{
    float: right;
    width: 80px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
  }
  .code{
    background-image: url('../../../../assets/medicine/code_normal_icon.png');
  }
  .code:hover{
    background-image: url('../../../../assets/medicine/code_hover_icon.png');
  }
  .desk{
    background-image: url('../../../../assets/medicine/desk_normal_icon.png');
  }
  .desk:hover{
    background-image: url('../../../../assets/medicine/desk_hover_icon.png');
  }
  .prompt{
    float: right;
    height: 26px;
    margin-top: 20px;
    font-size: 14px;
    color: #E93F35;
    line-height: 26px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .codePrompt{
    width: 121px;
    background-image: url('../../../../assets/medicine/code_tips_icon.png');
  }
  .deskPrompt{
    width: 178px;
    background-image: url('../../../../assets/medicine/desk_tips_icon.png');
  }
}
.registration-content-desk{
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  .content-tab{
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #E8E8E8;
    span{
      display: inline-block;
      height: 100%;
      font-size: 18px;
      color: #999;
      line-height: 25px;
      margin-right: 60px;
      position: relative;
      cursor: pointer;
    }
    .selected{
      color: #222;
    }
    .selected::after{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      border-radius:3px;
      background-color: #E93F35;
    }
  }
  .content-input{
    width: 100%;
    .errPrompt{
      width: 100%;
      min-height: 30px;
      font-size: 14px;
      line-height: 20px;
      color: #E93F35;
      padding-top: 10px;
      img{
        width: 18px;
        height: 18px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
}
.registration-content-code{
  width: 100%;
  padding: 36px 30px 90px;
  box-sizing: border-box;
  img{
    display: block;
    width: 180px;
    height: 180px;
    margin:0 auto 20px;
  }
  p{
    text-align: center;
    font-family:PingFang-SC-Semibold;
  }
  .code-prompt{
    font-size:18px;
    font-weight:600;
    color:rgba(34,34,34,1);
    line-height:25px;
    margin-bottom: 70px;
  }
  .code-bref{
    font-size:24px;
    font-weight:600;
    color:rgba(233,63,53,1);
    line-height:33px;
    letter-spacing:10px;
  }
}
.setting{
  padding: 60px 30px;
  box-sizing: border-box;
  .leapfrog{
    text-align: center;
    font-size:16px;
    color:#222222;
    line-height:22px;
    margin-top: 75px;
    cursor: pointer;
  }
}
.resetPassword{
  padding: 60px 30px 72px;
  box-sizing: border-box;
  .content-tab{
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 40px;
    p{
      text-align: center;
      font-size:18px;
      font-weight:600;
      color:#222222;
      line-height:30px;
    }
  }
}
.inputBox{
  width:100%;
  height:50px;
  border-radius:6px;
  border:1px solid #E8E8E8;
  padding: 8px 8px 8px 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  input{
    width: 100%;
    height: 100%;
    border: 0;
    outline: none;
    font-size: 16px;
    color: #222;
  }
  .codeInput{
    width: 60%;
  }
  .sendCode{
    display: inline-block;
    float: right;
    width:110px;
    height:100%;
    background:#E2E2E2;
    border-radius:2px;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    color: #222;
    cursor: pointer;
  }
  .sendCode:hover{
    background:#CCCCCC;
  }
  .passInput{
    width: 70%;
  }
  .forgetPass{
    display: inline-block;
    float: right;
    width:64px;
    margin-right: 12px;
    font-size:14px;
    color:#999999;
    line-height:32px;
    cursor: pointer;
  }
}
.inputVelue{
  border:1px solid #CCCCCC;
}
.inputBox:hover{
  border:1px solid #CCCCCC;
}
.setting-prompt{
  font-size:14px;
  font-weight:400;
  color:#999;
  line-height:20px;
  margin-bottom: 20px;
}
.protocol{
  width: 100%;
  font-size: 14px;
  color: #999;
  line-height: 26px;
  cursor: default;
}
.content-btn{
  margin-top: 40px;
  width:100%;
  height:50px;
  background:rgba(226,226,226,1);
  
  border-radius:6px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  cursor: default;
}
.btnClick:hover{
  background: #EE4444;
}
.btnClick{
  background:linear-gradient(98deg,rgba(251,119,89,1) 0%,rgba(245,75,75,1) 100%);
  cursor: pointer;
  // background: #DF3838;
}
.select-box{
  margin: 0 auto;
  width:880px;
  height: 606px;
  background:#fff;
  box-shadow:0px 0px 20px 0px rgba(0,0,0,0.14);
  border-radius:6px;
  padding: 0 50px 50px;
  .floor{
    width: 100%;
    padding: 30px 0 0;
    ul{
      width: 100%;
      li{
        display: inline-block;
        margin-right: 30px;
        margin-bottom: 20px;
        span{
          display: inline-block;
          width: 90px;
          font-size: 14px;
          color: #666;
          line-height: 26px;
          text-align: center;
          background: #f4f4f4;
          border-radius: 13px;
          cursor: pointer;
        }
        img{
          cursor: pointer;
        }
      }
    }
  }
  .first-floor{
    border-bottom: 1px solid #E8E8E8;
  }
}
.noPermission{
  padding: 90px 0;
  box-sizing: border-box;
  img{
    display: block;
    margin: 0 auto;
    width: 180px;
    height: 180px;
  }
  h4{
    text-align: center;
    font-size:18px;
    color:rgba(34,34,34,1);
    line-height:25px;
    margin: 60px 0 30px;
  }
  p{
    text-align: center;
    font-size:14px;
    font-weight:400;
    color:rgba(51,51,51,1);
    line-height:30px;
  }
}
.department{
  .floor{
    padding-bottom: 10px;
    ul{
      li{
        .newWidth{
          width: auto;
          padding: 0 10px;
        }
        .selectedSec{
          color: #fff;
          background:linear-gradient(90deg,rgba(251,119,89,1) 0%,rgba(245,75,75,1) 100%);
        }
        .selectedFir{
          color: #DF3838;
        }
      }
    }
  }
}
.userType{
  .first-floor{
    width: 574px;
    padding: 0px 155px 133px; 
    border: 0;
    ul{
      li{
        width: 120px;
        height: 120px;
        margin-right: 30px;
        margin-bottom: 30px;
        img{
          display: block;
          width: 80px;
          height: 80px;
          margin: 0 auto;
          margin-bottom: 10px;
        }
        span{
          font-size: 16px;
          width: 100%;
          background: #fff;
          border-radius: 13px;
          color: #222;
        }
      }
      li:nth-child(4){
        margin-right: 0;
      }
    }
  }
}
.category{
  position: relative;
  .floor{
    p{
      width: 100%;
      font-size: 18px;
      color: #222;
      line-height: 25px;
      text-align: left;
      margin-bottom: 30px;
      span{
        font-size: 16px;
        color: #666;
        line-height: 22px;
      }
    }
    ul{
      li{
        img{
          width: 16px;
          height: 16px;
          vertical-align: top;
          margin-left: -16px;
          margin-top: -8px;
        }
      }
    }
  }
  .footer-btn{
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    width: 300px;
    margin: 0 auto;
    span{
      display: inline-block;
      width:100px;
      height:36px;
      background:linear-gradient(98deg,rgba(251,119,89,1) 0%,rgba(245,75,75,1) 100%);
      border-radius:20px;
      text-align: center;
      font-size:16px;
      line-height:36px;
      color: #fff;
      cursor: pointer;
    }
    .cancel{
      margin-left: 90px;
      background: #F4F4F4;
      color: #666666;
    }
  }
}
.tab-top{
  width: 100%;
  padding: 30px 0;
  font-size: 24px;
  line-height: 33px;
  color: #222;
  text-align: center;
  border-bottom: 1px solid #E8E8E8;
}
.content-desc{
  text-align: center;
  height:28px;
  font-size:20px;
  font-family:PingFang-SC-Regular,PingFang-SC;
  font-weight:400;
  color:rgba(34,34,34,1);
  line-height:28px;
  margin: 30px auto 60px;
}
.label-content{
  margin: 0 116px;
  // display: flex;
  // flex-wrap: wrap;
  // // justify-content: space-between;
  .label-item{
    display: inline-block;
    height:36px;
    width: 132px;
    text-align: center;
    background:rgba(244,244,244,1);
    border-radius:18px;
    font-size:18px;
    font-family:PingFang-SC-Regular,PingFang-SC;
    font-weight:400;
    color:rgba(34,34,34,1);
    line-height:36px;
    margin-bottom: 30px;
    cursor: pointer;
    margin-right: 40px;
  }
  .m-right0{
    margin-right: 0px !important;
  }
  .label-item-active{
    background:linear-gradient(90deg,rgba(251,119,89,1) 0%,rgba(253,74,74,1) 100%);
    color: #ffffff;
  }
}
.footer-label{
  position: absolute;
  bottom: 90px;
  left: 0;
  right: 0;
  width: 200px;
  margin: 0 auto;
  text-align: center;
  height:44px;
  background:linear-gradient(90deg,rgba(251,119,89,1) 0%,rgba(253,74,74,1) 100%);
  border-radius:22px;
  font-size:20px;
  font-family:PingFang-SC-Semibold,PingFang-SC;
  font-weight:600;
  color:rgba(255,255,255,1);
  line-height:44px;
  cursor: pointer;
}
.active-submit{
  background:linear-gradient(90deg,rgba(251,119,89,1) 0%,rgba(253,74,74,1) 100%);
  color:rgba(255,255,255,1);
}
</style>
