<template>
  <el-drawer
    custom-class='medic-drawer'
    :visible.sync="drawer"
    direction="rtl"
    :with-header="false"
    @click="closePage"
  >
    <div
      class="back medic-hover-primary"
      @click="drawer = false"
    >取消</div>
    <div class="drawer-top">
      <h1 class="title">收藏夹</h1>
      <div class="search-box">
        <medic-input
          :jump="false"
          :showSelectPanel="false"
          @getSearchContent="getSearchContent"
          type="small"
        />
      </div>
      <!-- 切换导航 -->
      <MedicTabs :selectTabList="$store.getters.collectMedicTabSelect" @getTabIndex="getTabIndex" />
    </div>
    <div class="collect-container">
      <div style="margin-bottom: 34px; min-height: calc(100vh - 324px)">
        <!-- 产品 -->
        <Product :list="productList" emptyType="collection" :isEmpty="isEmpty" @reload="getSearchContent({})" />
      </div>
      <div class="flex-center-center">
        <Pagination
          :total="pagination.total"
          :page-size="pagination.pageSize"
          :current-page="pagination.current"
          :pager-count="5"
          @current-change="changeCurrent"
          @size-change="changeSize"
          :showTotal="false"
        />
      </div>
    </div>
  </el-drawer>
</template>

<script>
import MedicInput from '@/views/herbalMedicine/components/SearchInput'
import Product from '@/views/herbalMedicine/components/ProductList'
import Pagination from '@/views/herbalMedicine/components/Pagination'
import MedicTabs from '@/views/herbalMedicine/components/MedicTabs'
import { poisonDrugCollectList } from '@/api/herbalMedicine'
export default {
  data () {
    return {
      form: {
        medicTabIndex: '-1',
        searchType: '',
        searchText: '',
      },
      drawer: false,
      pagination: {
        current: 0,
        pageSize: 8,
        total: 0,
      },
      productList: [],
      isEmpty: false
    }
  },
  methods: {
    // 点击搜索
    getSearchContent(scope) {
      if(scope) {
        Object.assign(this.form, scope)
      }
      const parmary = {
        start: this.pagination.current,
        limit: this.pagination.pageSize,
        drugName: this.form.searchText,
        drugType: this.form.medicTabIndex == '-1' ? '' : this.form.medicTabIndex
      }
      this.poisonDrugCollectList(parmary);
    },
    /** 搜索有毒药品收藏列表 */
    poisonDrugCollectList(scope) {
      poisonDrugCollectList(scope).then(res => {
        res.list.forEach(element => {
          element.isCollect = true;
        });
        this.productList = res.list;
        this.changeEmpty(this.productList)
        this.paginationConfig(res)
      })
    },
    changeEmpty(productList) {
      if(productList.length === 0) 
        this.isEmpty = true
      else 
        this.isEmpty = false
    },
    // 处理分页
    paginationConfig(page) {
      this.pagination.pageSize = page.limit;
      this.pagination.total = page.count;
    },
    // 获取getTabIndex
    getTabIndex(index) {
      this.form.medicTabIndex = index;
      this.pagination.current = 0;
      this.getSearchContent({})
    },
    // 点击下一页
    changeCurrent(size) {
      console.log('点击下一页', size)
      this.pagination.current = size;
      this.getSearchContent();
    },
    // 切换页面
    changeSize(size) {
      console.log('切换页面', size)
      this.pagination.current = size;
      this.getSearchContent();
    },
    closePage () {
      this.$emit('close')
    },
    changeDrawer (bool) {
      if(bool == 'close')
        this.drawer = false
      else if(bool == 'open') 
        this.drawer = true
      else
        this.drawer = !this.drawer
    },
  },
  components: {
    MedicInput,
    Product,
    Pagination,
    MedicTabs
  },
  watch: {
    drawer(val) {
      if(val) {
        this.getSearchContent();
      }
    }
  }
}
</script>

<style lang="scss" scope>
.medic-drawer {
  width: 640px !important;
  background: #f7f7f7;
  .el-drawer__body {
    position: relative;
    .drawer-top {
      padding: 26px 29px 0 28px;
      background: #ffffff;
    }
    .back {
      position: absolute;
      cursor: pointer;
      top: 24px;
      left: 28px;
      padding-left: 21px;
      width: 56px;
      height: 24px;
      line-height: 24px;
      background-color: rgba(215, 0, 15, 0.1);
      border-radius: 2px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 600;
      color: #d7000f;
      background-image: url("./../../../assets/medicine/back.png");
      background-size: 5px 10px;
      background-repeat: no-repeat;
      background-position: 10px center;
    }
    .title {
      margin: 0;
      margin-bottom: 30px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
    }
    .search-box {
      margin-bottom: 20px;
    }
    .collect-container {
      height: calc(100vh - 179px);
      padding: 16px 28px 60px 28px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 2px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动方块*/
        position: absolute;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.2);
      }
      &::-webkit-scrollbar-thumb:hover {
        /*滚动方块hover*/
        background-color: #d7000f;
      }
      &::-webkit-scrollbar-track {
        /*滚动条轨道*/
        /* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.1); */
        border-radius: 5px;
        background: transparent;
      }
      .search-list {
        .item {
          margin-right: 14px;
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>