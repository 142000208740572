<template>
  <div>
    <el-dialog
      custom-class="swiper-dialog"
      :visible.sync="showSwiper"
      :fullscreen="true"
      :show-close="false"
      @closed="closePage"
    >
      <div class="medic-user-info">
        <div class="close" @click="showSwiper = false"></div>
        <h1 class="title">个人信息</h1>
        <el-form class="medic-info-form" ref="medicInfo" :model="userInfo" :rules="userInfoRules" label-width="58px">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="userInfo.name" placeholder="请输入姓名" type="text" clearable></el-input>
          </el-form-item>
          <el-form-item label="身份" prop="type">
            <el-select v-model="userInfo.type" :popper-append-to-body="false" @change="changeType">
              <!-- <el-option :key="0" label="1" :value="0"></el-option> -->
              <el-option :key="1" label="医生" :value="1"></el-option>
              <el-option :key="2" label="行政" :value="2"></el-option>
              <el-option :key="3" label="药师" :value="3"></el-option>
              <el-option :key="4" label="护士" :value="4"></el-option>
              <el-option :key="5" label="医学生" :value="5"></el-option>
              <!-- <el-option :key="6" label="其他" :value="6"></el-option> -->
              <el-option :key="7" label="医技" :value="7"></el-option>
              <!-- <el-option :key="9" label="机构" :value="9"></el-option> -->
            </el-select>
          </el-form-item>
          <!-- 医学生不展示 -->
          <el-form-item label="医院" prop="hospital" v-if="userInfo.type !== 5">
            <el-cascader ref="domHospital" :placeholder="userInfo.place_hospital" v-model="userInfo.hospital" :multiple="true" :props="hospitalList" :show-all-levels="false" @change="changeHospital"></el-cascader>
          </el-form-item>
          <!-- 医学生不展示 -->
          <el-form-item label="科室" prop="department" v-if="userInfo.type !== 5">
            <el-cascader ref="domDepartment" :placeholder="userInfo.place_department" v-model="userInfo.department" :options="showDepartmentList" :props="departemtProps" :show-all-levels="false" @change="changeDepartment"></el-cascader>
          </el-form-item>
          <!-- 医学生展示 -->
          <el-form-item label="学校" prop="school" v-if="userInfo.type == 5">
            <el-cascader ref="domSchool" :placeholder="userInfo.place_school" v-model="userInfo.school" :props="schoolList" :show-all-levels="false" @change="changeSchool"></el-cascader>
          </el-form-item>
          <!-- 医学生展示 -->
          <el-form-item label="专业" prop="major" v-if="userInfo.type == 5">
            <el-cascader ref="domMajor" :placeholder="userInfo.place_major" v-model="userInfo.major" :options="majorList" :props="majorProps" :show-all-levels="false" @change="changeMajor"></el-cascader>
          </el-form-item>
          <!-- 行政不展示 -->
          <el-form-item label="职称" prop="title" v-if="userInfo.type !== 2">
            <el-select v-model="userInfo.title" :popper-append-to-body="false" @change="changeTitle">
              <el-option v-for="item in showTitleList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-button class="sure" @click="submitForm" :loading="submitLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { synchroUserInfo, getUserInfo, getTitleList, saveUserInfo, getProvinceList, getMajorList, getDepartmentList, getSchoolList, getCityList, getDistrictList, getHospitalList } from '@/api/login'
let id = 0;
export default {
  props: {},
  data () {
    return {
      showSwiper: true,
      submitLoading: false, // 提交表单loading
      // 用户信息
      userInfo: {
        name: "", // 姓名
        type: null, // 用户身份【0-缺省值,1-医生,2-行政,3-药师,4-护士,5-学生,6-其他,7-医技,9-机构】
        hospital: '', //  医院
        department: '', //  科室
        title: '', //  职称
        school: '', // 学校
        major: ''
      },
      userInfoRules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        type: [{ required: true, message: "请选择用户身份", trigger: "blur" }],
        department: [{ required: true, message: "请选择科室", trigger: "blur" }]
      },
      titleList: [],  // 职称列表
      showTitleList: [], // 显示的职称列表
      departmentList: [], // 科室列表
      showDepartmentList: [], // 显示的科室列表
      majorList: [], // 专业名称
      departemtProps: {
        value: 'dptid',
        label: 'name',
        children: 'childs'
      },
      majorProps: {
        value: 'mid',
        label: 'name',
        children: 'childs'
      },
      hospitalList: {
        lazy: true,
        lazyLoad (node, resolve) {
          const { level, data } = node;
          console.log(node)
          const nodes = [];
          // if(node.level)
          // 一级菜单调用接口
          if(level == 0) {
            // 获取省份
            getProvinceList().then(res => {
              res.list.map(item => {
                nodes.push({
                  value: item.pvcid,
                  label: item.name,
                  leaf: level >= 2,
                  directly: item.directly
                })
              });
              resolve(nodes);
            })
          } else if (level == 1) {  // 二级菜单调用接口
            if(data.directly == 1) {
              // 直辖市
              getDistrictList({pvcid: data.value, ctyid: 0}).then(res => {
                res.list.map(item => {
                  nodes.push({
                    value: item.dstid,
                    label: item.name,
                    leaf: level >= 2,
                    directly: 1
                  })
                });
                resolve(nodes);
              })
            } else {
              // 获取地区
              getCityList({pvcid: data.value}).then(res => {
                res.list.map(item => {
                  nodes.push({
                    pvcid: data.value,
                    value: item.ctyid,
                    label: item.name,
                    leaf: level >= 2,
                    directly: 0
                  })
                });
                resolve(nodes);
              })
            }
          } else if (level == 2) {
            if(data.directly == 1) {
              getHospitalList({dstid: data.value}).then(res => {
                res.list.map(item => {
                  nodes.push({
                    value: item.hspid,
                    label: item.name,
                    leaf: level >= 2
                  })
                });
                resolve(nodes);
              })
            } else {
              getDistrictList({ctyid: data.value,pvcid: data.pvcid}).then(res => {
                res.list.map(item => {
                  nodes.push({
                    value: item.dstid,
                    label: item.name,
                    leaf: level >= 3
                  })
                });
                resolve(nodes);
              })
            }
          } else if (level == 3) {
            getHospitalList({dstid: data.value}).then(res => {
              res.list.map(item => {
                nodes.push({
                  value: item.hspid,
                  label: item.name,
                  leaf: level >= 3
                })
              });
              resolve(nodes);
            })
          }
          // 通过调用resolve将子节点数据返回，通知组件数据加载完成
        }
      },
      // 学校列表
      schoolList: {
        lazy: true,
        lazyLoad (node, resolve) {
          const { level, data } = node;
          console.log(node)
          const nodes = [];
          // if(node.level)
          // 一级菜单调用接口
          if(level == 0) {
            // 获取省份
            getProvinceList().then(res => {
              res.list.map(item => {
                nodes.push({
                  value: item.pvcid,
                  label: item.name,
                  leaf: level >= 2,
                  directly: item.directly
                })
              });
              resolve(nodes);
            })
          } else if (level == 1) {  // 二级菜单调用接口
            if(data.directly == 1) {
              // 直辖市学校
              getSchoolList({pvcid: String(data.value), ctyid: '0',start: 1, limit: 3000}).then(res => {
                res.list.map(item => {
                  nodes.push({
                    value: item.id,
                    label: item.name,
                    leaf: level >= 1
                  })
                });
                resolve(nodes);
              })
            } else {
              // 获取地区
              getCityList({pvcid: data.value}).then(res => {
                res.list.map(item => {
                  nodes.push({
                    pvcid: data.value,
                    value: item.ctyid,
                    label: item.name,
                    leaf: false,
                    directly: 0
                  })
                });
                resolve(nodes);
              })
            }
          } else if (level == 2) {
            getSchoolList({ctyid: String(data.value),pvcid: String(data.pvcid),start: 1, limit: 3000}).then(res => {
              res.list.map(item => {
                nodes.push({
                  value: item.id,
                  label: item.name,
                  leaf: true
                })
              });
              resolve(nodes);
            })
          }
        }
      }
    }
  },
  created() {
    this.getUserInfo();
    this.getTitleList();
    this.getDepartmentList();
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      getUserInfo().then(res => {
        this.userInfo = res
        // 设置placehoder 代替默认值
        this.userInfo.place_hospital = this.userInfo.hospital;
        this.userInfo.place_department = this.userInfo.department;
        this.userInfo.place_school = this.userInfo.school;
        this.userInfo.place_major = this.userInfo.major;
      })
    },
    // 获取职称列表
    getTitleList() {
      getTitleList().then(res => {
        this.titleList = res;
      })
    },
    // 获取科室信息
    getDepartmentList() {
      getDepartmentList().then(res => {
        this.departmentList = res;
      })
    },
    // 获取专业列表
    getMajorList() {
      getMajorList().then(res => {
        this.majorList = res.list;
      })
    },
    // 切换身份
    changeType(e) {
      const scope = {
        property: "type",
        value: e
      }
      this.saveUserInfo(scope)
      this.clearUserInfoCustom();
    },
    // 更改学校
    changeSchool(e) {
      console.log(e)
      const scope = {
        property: "school",
        value: this.$refs.domSchool.getCheckedNodes() ? this.$refs.domSchool.getCheckedNodes()[0].label : ''
      }
      this.saveUserInfo(scope)
    },
    // 更改专业
    changeMajor(e,msg) {
      const scope = {
        property: "major",
        value: this.$refs.domMajor.getCheckedNodes() ? this.$refs.domMajor.getCheckedNodes()[0].label : ''
      }
      this.saveUserInfo(scope)
    },
    // 更改职称选中
    changeTitle(e) {
      const scope = {
        property: "title",
        value: e
      }
      this.saveUserInfo(scope)
    },
    // 更改医院
    changeHospital(e) {
      console.log(this.$refs.domHospital)
      console.log(e)
      const scope = {
        hspid: e[e.length - 1],
        property: "hospital",
        value: this.$refs.domHospital.getCheckedNodes() ? this.$refs.domHospital.getCheckedNodes()[0].label : ''
      }
      this.saveUserInfo(scope)
    },
    // 更改科室选中
    changeDepartment(e) {
      console.log(this.$refs.domDepartment)
      const scope = {
        property: "department",
        value: this.$refs.domDepartment.getCheckedNodes() ? this.$refs.domDepartment.getCheckedNodes()[0].label : ''
      }
      this.saveUserInfo(scope)
    },
    // 保存用户信息
    saveUserInfo(scope) {
      return new Promise((resolve, reject) => {
        saveUserInfo(scope).then(res => {
          console.log('saveUserInfo', res)
          this.submitLoading = false;
          resolve();
        }).catch(() => {
          this.submitLoading = false;
          reject();
        })
      }) 
    },
    // 提交表单
    submitForm() {
      this.$refs.medicInfo.validate((valid) => {
        if(valid) {
          this.submitLoading = true;
          const scope = {
            property: "name",
            value: this.userInfo.name
          }
          this.saveUserInfo(scope).then(()=> {
            this.submitLoading = false;
            this.closePage();
          }).catch(() => {
            this.submitLoading = false;
          })
          // synchroUserInfo(this.userInfo).then(res => {
          //   this.submitLoading = false;
          // }).catch(() =>{
          //   this.submitLoading = false;
          // })
        }
      })
    },
    // 清空选项
    clearUserInfoCustom() {
      // 清空科室
      this.userInfo.department = '';
      // 清空职称
      this.userInfo.title = '';
    },
    closePage () {
      this.$emit('close')
    }
  },
  watch: {
    userInfo: {
      handler(val) {
        const type = val.type
        let title_list = [];
        let department_list = [];
        switch (type) {
          // 医生
          case 1:
            title_list = this.titleList.doctor;
            department_list = this.departmentList.doctor;
            break;
          // 药师
          case 3:
            title_list = this.titleList.druggist;
            department_list = this.departmentList.druggist;
            break;
          // 护士
          case 4:
            title_list = this.titleList.nurse;
            department_list = this.departmentList.nurse;
            break;
          // 医学生
          case 5:
            title_list = this.titleList.student;
            // 身份为医学生时候 初始化专业列表
            this.getMajorList()
            // department_list = this.departmentList.student;
            break;
          // 医技
          case 7:
            title_list = this.titleList.medicalSkills;
            department_list = this.departmentList.medicalSkills;
            break;
          default:
            title_list = this.titleList.doctor;
            department_list = this.departmentList.doctor;
            break;
        }
        // 复制职称显示列表
        this.showTitleList = title_list;
        this.showDepartmentList = department_list;
      },
      deep: true
    },
  }
}
</script>

<style lang="scss" scope>
.swiper-dialog {
  background: transparent;
  .el-dialog__header {
    height: 0;
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
  .medic-user-info{
    position: relative;
    padding: 23px 30px 59px;
    width: 420px;
    // height: 557px;
    background: #FFFFFF;
    border-radius: 2px;
    box-sizing: border-box;
    .close{
      position: absolute;
      width: 24px;
      height: 24px;
      top: 20px;
      right: 20px;
      background: url('../../../assets/medicine/close.png') center center no-repeat;
      background-size: 24px 24px;
      cursor: pointer;
    }
    .title{
      margin: 0;
      margin-bottom: 23px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }
    .medic-info-form{
      .el-form-item{
        border: 1px solid #E8E8E8;
        height: 50px;
        line-height: 50px;
        box-sizing: border-box;
        .el-form-item__error{
          color: #D7000F;
        }
        .el-form-item__label{
          margin: 10px 0;
          line-height: 30px;
          border-right: 1px solid #E8E8E8;
        }
        .el-input__inner{
          height: 48px;
          line-height: 48px;
          border: none;
          font-size: 16px;
          color: #333333;
          font-weight: 500;
        }
        .flex-text{
          position: absolute;
          width: 100%;
          height: 48px;
          line-height: 48px;
          border: none;
          font-size: 16px;
          color: #333333;
          font-weight: 500;
          padding-left: 15px;
          box-sizing: border-box;
          cursor: pointer;
          z-index: 10;
          &>div{
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            background: #FFFFFF;
          }
        }
        .el-cascader{
          .el-input__inner{
            &:-ms-input-placeholder {
              color: #333333;
            }

            &::-webkit-input-placeholder {
              color: #333333;
            }

            &::-moz-placeholder {
              color: #333333;
            }

            &:-moz-placeholder {
              color: #333333;
            }
          }
        }
        .el-select,
        .el-cascader{
          width: 100%;
        }
        .el-select-dropdown__item.selected,
        .el-cascader-node.in-active-path{
          color: #D7000F;
        }
        &.is-required{
          &:not(.is-no-asterisk)>.el-form-item__label:before{
            display: none;
          }
          &:not(.is-no-asterisk)>.el-form-item__label:after{
            content: '*';
            color: #D7000F;
          }
        }
      }
    }
    .sure{
      margin-top: 28px;
      width: 360px;
      height: 50px;
      background: #D7000F;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
    }
  }
}
</style>